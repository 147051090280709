import React, { useState } from 'react'
import { LicenseService } from '../../../services/LicenseService'
import { BusinessUnit, ProductWithRolesDto } from '../../../services/Dtos'
import { userIsAdmin, userIsMarketAdmin } from '../../../lib/jwt'

type Props = {
  accountId: string
  businessUnit: BusinessUnit
  productsWithRoles: ProductWithRolesDto[]
  onSaved: any
}

const labelClasses = 'block mb-sm'

export const AddLicenseForm = ({ accountId, businessUnit, productsWithRoles, onSaved }: Props) => {
  const [selectedProduct, setSelectedProduct] = useState('NotifyAssist')
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10))
  const [expiryDate, setExpiryDate] = useState('')
  const [orderedBy, setOrderedBy] = useState('')
  const [supportTicket, setSupportTicket] = useState('')
  const [invoicedFrom, setInvoicedFrom] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const fullSelectedProduct = productsWithRoles.find(p => p.product === selectedProduct)

  return (
    <form
      action="#"
      onSubmit={e => {
        e.preventDefault()
        setErrorMessage('')
        setIsSubmitting(true)
        const stagedLicense = {
          product: selectedProduct,
          to: expiryDate,
          from: startDate,
          orderedBy: orderedBy,
          ticketNo: supportTicket,
          invoicedFrom: invoicedFrom,
          scopes: []
        }
        LicenseService.createLicenses(accountId, stagedLicense)
          .then(() => {
            onSaved()
          })
          .catch(error => {
            setIsSubmitting(false)
            setErrorMessage(error.message)
          })
      }}
      className="p-md"
    >
      <h1 className="text-xl mb-lg">Add License</h1>

      <div className="flex gap-md items-center">
        <div className="flex-1">
          <label htmlFor="product" className={labelClasses}>
            Product
          </label>

          <select
            id="product"
            aria-label="Select product"
            className="select"
            value={selectedProduct}
            onChange={({ target }) => setSelectedProduct(target.value)}
          >
            {productsWithRoles
              .filter(p => userIsAdmin() || (userIsMarketAdmin() && p.availableForMarketAdmin))
              .map(({ product }) => (
                <option key={product} value={product}>
                  {product}
                </option>
              ))}
          </select>
        </div>
        <div className="flex-1">
          <label htmlFor="startDate" className={labelClasses}>
            Start Date
          </label>
          <input
            id="startDate"
            type="date"
            value={startDate}
            onChange={({ target }) => {
              setStartDate(target.value)
              if (expiryDate && Date.parse(target.value) > Date.parse(expiryDate)) {
                setExpiryDate(target.value)
              }
            }}
          />
        </div>
        <div className="flex-1">
          <label htmlFor="expiryDate" className={labelClasses}>
            Expiry Date
          </label>
          <input
            id="expiryDate"
            type="date"
            min={startDate}
            value={expiryDate}
            onChange={({ target }) => setExpiryDate(target.value)}
          />
        </div>
        <div className="flex-1">
          <label htmlFor="orderedBy" className={labelClasses}>
            Ordered By
          </label>
          <input id="orderedBy" type="text" value={orderedBy} onChange={({ target }) => setOrderedBy(target.value)} />
        </div>
        <div className="flex-1">
          <label htmlFor="supportTicket" className={labelClasses}>
            Support Ticket #
          </label>
          <input
            id="supportTicket"
            type="text"
            value={supportTicket}
            onChange={({ target }) => setSupportTicket(target.value)}
          />
        </div>
        <div className="flex-1">
          <label htmlFor="invoicing" className={labelClasses}>
            Invoicing
          </label>
          <input
            id="invoicing"
            type="date"
            value={invoicedFrom}
            disabled={!businessUnit.invoiceable || !fullSelectedProduct?.invoiceable}
            onChange={({ target }) => setInvoicedFrom(target.value)}
          />
        </div>
      </div>
      {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
      <div className="pt-lg">
        <button className="btn btn-primary-dark" disabled={isSubmitting}>
          {isSubmitting && <div className="loadingSpinner" />} Create License
        </button>
      </div>
    </form>
  )
}
